<template>
    <div class="d-flex justify-content-end align-items-center">
        <!--btn add | update | delete-->

        <b-button
            v-if="courseQDto.id"
            variant="primary"
            class="mx-1"
            @click="modify"
            >تعديل
        </b-button>
        <b-button v-else variant="primary" class="mx-1" @click="submit"
            >اضافة
        </b-button>
      
        <b-button variant="outline-primary" @click="$router.go(-1)"
            >تراجع
        </b-button>
        <b-button variant="danger" class="ml-1" @click="deleteQ(courseQDto.id)"
      >حذف</b-button
    >
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    computed: {
        ...mapState({
            courseQDto: (state) => state.courseQuestion.courseQDto,
        }),
    },
    methods: {
        ...mapActions([
            "updateCourseQuestion",
            "addCourseQuestions",
            "updateCourseQuestion",
            "deleteCourseQuestion",
        ]),
        deleteQ(id) {
            this.$store.dispatch("deleteCourseQuestion", [id]);
        },
        submit() {
            this.addCourseQuestions({
                ...this.courseQDto,
                id: null,
                tags: this.courseQDto.tags
                    ? this.courseQDto.tags.map((el) => el.id)
                    : [],
                answers:
                    this.courseQDto.answers != []
                        ? this.courseQDto.answers
                        : null,
            }).then((res) => {
                console.log("res", res);
                if (res === 200) {
                    this.$router.go(-1);
                }
            });
        },
        modify() {
            console.log(this.courseQDto);
            this.updateCourseQuestion({
                ...this.courseQDto,
                tags: this.courseQDto.tags
                    ? this.courseQDto.tags.map((el) => el.id)
                    : [],
                answers:
                    this.courseQDto.answers != []
                        ? this.courseQDto.answers
                        : null,
            }).then((res) => {
                console.log("res", res);
                if (res === 200) {
                    this.$router.go(-1);
                }
            });
            console.log(this.courseQDto.answers,"this.courseQDto.answers");
            
        },
    },
};
</script>
